import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

import Title from "./Title";

const generateData = () => [
  { id: 0, value: 45, label: "Frio" },
  { id: 1, value: 35, label: "Morno" },
  { id: 2, value: 20, label: "Quente" },
];

export default function GraficoTemperatura() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Title>Temperatura</Title>
      <div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
        <PieChart
          margin={{ top: 20, bottom: 30, left: 0, right: 35  }}
          series={[
            {
              arcLabel: (item) => `${item.value}%`,
              arcLabelMinAngle: 45,
              data: generateData(),
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
          width={350}
          height={200}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: -6,
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
