import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ResumoLembretes from "./ResumoLembretes";
import ProximosAgendamentos from "./ProximosAgendamentos";
import GraficoTaxaSatisfacao from "./GraficoTaxaSatisfacao";
import GraficoAgendamentos from "./GraficoAgendamentos";
import GraficoConversas from "./GraficoConversas";
import ResumoConversoes from "./ResumoConversoes";
import LeadsQuentes from "./LeadsQuentes";
import GraficoTemperatura from "./GraficoTemperatura";

export default function Painel() {
  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <GraficoConversas />
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <ResumoConversoes />
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12} md={8} lg={8}>
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column", height: 325 }}
        >
          <LeadsQuentes />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 325,
          }}
        >
          <GraficoTemperatura />
        </Paper>
      </Grid>
    </Grid>
  );
}
