import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LeadFields from "./LeadFields";
import LeadInsights from "./LeadInsights";
import moment from "moment-timezone";

export default function LeadDetails() {
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const [leadDetailsData, setLeadDetailsData] = useState({
    lead: { params: {}, message: [] },
  });
  const { leadId } = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios(
          `${process.env.REACT_APP_PRONTOPLAY_API}/leads/${leadId}`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );
        setLeadDetailsData(result.data);
      } catch (err) {
        console.log(`Error when trying to fetch data`);
      }
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const formatDateBR = (date) =>
    !!date
      ? moment.tz(date, "America/Sao_Paulo").format("DD/MM/YYYY HH:mm")
      : "";

  return (
    <Grid container spacing={3}>
      <LeadFields lead={leadDetailsData.lead} />
      <LeadInsights lead={leadDetailsData.lead} />
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Title>Mensagens</Title>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Remetente</TableCell>
                  <TableCell>Mensagem</TableCell>
                  <TableCell>Data/Hora</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadDetailsData.lead.message.map((leadMessage) => (
                  <TableRow key={leadMessage.id}>
                    <TableCell>
                      {leadMessage.sender === "LEAD" ? "Lead" : "Chatbot"}
                    </TableCell>
                    <TableCell>{leadMessage.content}</TableCell>
                    <TableCell>
                      {formatDateBR(leadMessage.created_at)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Link
              color="primary"
              href="#"
              onClick={preventDefault}
              sx={{ mt: 3 }}
            >
              Ver mais
            </Link> */}
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
