import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ResumoLembretes from "./ResumoLembretes";
import ProximosAgendamentos from "./ProximosAgendamentos";
import GraficoTaxaSatisfacao from "./GraficoTaxaSatisfacao";
import GraficoAgendamentos from "./GraficoAgendamentos";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import axios from "axios";
import { TextareaAutosize } from "@mui/material";
import { Input } from "@mui/base/Input";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function CampaignDetails() {
  const navigate = useNavigate();
  const { campaignId } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();

    async function submitForm() {
      try {
        const data = new FormData(event.currentTarget);
        data.append("messages", JSON.stringify(campaignData.messages));
        data.append("start_at", new Date().toISOString());
        const result = await axios({
          method: "post",
          url: `${process.env.REACT_APP_PRONTOPLAY_API}/campaigns`,
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
          data,
        });
        navigate("/campanhas");
      } catch (err) {
        const errMsgs = !!err?.response?.data?.errors
          ? err.response.data.errors.map((item) => item.message)
          : [];
        alert(`Erro ao tentar criar a campanha: ${errMsgs.join("\n")}`);
      }
    }

    async function postUpdate() {
      try {
        const result = await axios({
          method: "post",
          url: `${process.env.REACT_APP_PRONTOPLAY_API}/campaigns/${campaignId}`,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          data: campaignData,
        });
        navigate("/campanhas");
      } catch (err) {
        const errMsgs = !!err?.response?.data?.errors
          ? err.response.data.errors.map((item) => item.message)
          : [];
        alert(`Erro ao tentar salvar a campanha: ${errMsgs.join("\n")}`);
      }
    }

    if (campaignId === "nova") {
      submitForm();
    } else {
      postUpdate();
    }
  };

  const [campaignData, setCampaignData] = useState({
    messages: [""],
    interval_min: 60,
    interval_max: 300,
  });
  const handleAddMessage = (e) => {
    setCampaignData({
      ...campaignData,
      messages: [...campaignData.messages, ""],
    });
  };
  const handleDeleteMessage = (index) => (e) => {
    setCampaignData({
      ...campaignData,
      messages: campaignData.messages.toSpliced(index, 1),
    });
  };

  const handleChangeMessage = (e) => {
    const messageId = e.target.id.split("_")[1];
    campaignData.messages[messageId] = e.target.value;
    setCampaignData({
      ...campaignData,
    });
  };

  const handleChange = (e) => {
    setCampaignData({
      ...campaignData,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        `${process.env.REACT_APP_PRONTOPLAY_API}/campaigns/${campaignId}`,
        { headers: { Authorization: localStorage.getItem("token") } }
      );
      setCampaignData(result.data);
    }
    if (campaignId !== "nova") {
      fetchData();
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex" }}>
        {/* <CardActionArea component="a" href="#"> */}
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {campaignId === "nova" ? "Nova" : "Editar"} Campanha
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              // onReset={handleReset}
              noValidate
              sx={{ mt: 1 }}
            >
              <br />
              <TextField
                label="Nome da Campanha"
                id="name"
                name="name"
                value={campaignData.name}
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
              />
              <br />
              <br />
              {campaignId === "nova" && (
                <TextField
                  type="file"
                  name="file"
                  label="Upload arquivo CSV com leads"
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              )}

              <br />
              <br />

              <FormLabel>Intervalo entre as mensagens (em segundos)</FormLabel>
              <br />
              <br />
              <Grid container xs={12} spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    label="Min"
                    id="interval_min"
                    name="interval_min"
                    value={campaignData.interval_min}
                    style={{ width: "100%" }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Max"
                    id="interval_max"
                    name="interval_max"
                    value={campaignData.interval_max}
                    style={{ width: "100%" }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <br />
              <FormLabel>Mensagens</FormLabel>

              <br />
              <br />
              <Grid container spacing={2} xs={12}>
                {campaignData.messages.map((message, index) => (
                  <Grid container item>
                    <Grid item xs={8}>
                      <TextField
                        label="Conteúdo da mensagem"
                        id={`campaignMessage_${index}`}
                        multiline
                        style={{ width: "100%" }}
                        onChange={handleChangeMessage}
                        rows={2}
                        maxRows={4}
                        InputLabelProps={{ shrink: true }}
                        value={message}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={4} alignSelf="center">
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon onClick={handleDeleteMessage(index)} />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <IconButton edge="end" aria-label="delete">
                <AddIcon onClick={handleAddMessage} />
              </IconButton>

              <br />
              <br />
              <Button variant="contained" type="submit">
                Salvar Campanha
              </Button>
            </Box>
          </CardContent>
        </Card>
        {/* </CardActionArea> */}
      </Grid>
    </Grid>
  );
}
