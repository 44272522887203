import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ResumoLembretes from "./ResumoLembretes";
import ProximosAgendamentos from "./ProximosAgendamentos";
import GraficoTaxaSatisfacao from "./GraficoTaxaSatisfacao";
import GraficoAgendamentos from "./GraficoAgendamentos";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import axios from "axios";
import { TextareaAutosize } from "@mui/material";
import { Input } from "@mui/base/Input";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function Configuracoes() {
  const POLLING_INTERVAL = 1000; // Poll every 1 seconds

  const [tenantData, setTenantData] = useState({
    tenant: {},
    instance: {},
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios(
          `${process.env.REACT_APP_PRONTOPLAY_API}/tenant/connect`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );
        setTenantData(result.data);
      } catch (err) {
        console.error("error when trying to fetch tenant connection info");
      }
    }
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex" }}>
        {/* <CardActionArea component="a" href="#"> */}
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Configurações
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {tenantData.tenant.name}
            </Typography>
            <Typography component="p" variant="h6">
              <br />
              Conexão com Whatsapp
            </Typography>
            {(tenantData.instance.status === "connecting" ||
              tenantData.instance.status === "close") && (
              <img src={tenantData.tenant.qrcode} alt="Whatsapp QR Code" />
            )}
            {tenantData.instance.status === "open" && (
              <Typography variant="subtitle1" color="text.secondary">
                Status da conexão: Conectado
              </Typography>
            )}
          </CardContent>
        </Card>
        {/* </CardActionArea> */}
      </Grid>
    </Grid>
  );
}
