import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ResumoLembretes from "./ResumoLembretes";
import ProximosAgendamentos from "./ProximosAgendamentos";
import GraficoTaxaSatisfacao from "./GraficoTaxaSatisfacao";
import GraficoAgendamentos from "./GraficoAgendamentos";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import axios from "axios";
import { TextareaAutosize } from "@mui/material";
import { Input } from "@mui/base/Input";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BotFlow from "./BotFlow";

export default function BotConfig() {


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex" }}>
        {/* <CardActionArea component="a" href="#"> */}
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              ChatBot
            </Typography>
            
            <BotFlow/>
          </CardContent>
        </Card>
        {/* </CardActionArea> */}
      </Grid>
    </Grid>
  );
}
