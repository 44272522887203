import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import SignIn from "./components/SignIn";
import LeadsTable from "./components/LeadsTable";
import Leads from "./components/Leads";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";
import Painel from "./components/Painel";
import LeadDetails from "./components/LeadDetails";
import OAuth2Callback from "./components/OAuth2Callback";
import Configuracoes from "./components/Configuracoes";
import LeadsKanban from "./components/LeadsKanban";
import Campaigns from "./components/Campaigns";
import CampaignDetails from "./components/CampaignDetails";
import CampaignLeads from "./components/CampaignLeads";
import BotConfig from "./components/BotConfig";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/test" element={<div>Hello World</div>} />
            <Route path="/oauth2callback" element={<OAuth2Callback />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Painel />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/chatbot" element={<BotConfig />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/campanhas" element={<Campaigns />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/campanhas/:campaignId" element={<CampaignDetails />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/campanhas/:campaignId/leads" element={<CampaignLeads />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/leads" element={<Leads />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/leads/:leadId" element={<LeadDetails />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/kanban" element={<LeadsKanban />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/configuracoes" element={<Configuracoes />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
