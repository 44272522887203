import * as React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment-timezone";
import { CSVLink, CSVDownload } from "react-csv";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";

export default function CampaignLeads() {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const handleDetailsClick = (route) => (e) => {
    e.preventDefault();
    navigate(route);
    return;
  };

  const [campaignLeadsData, setCampaignLeadsData] = useState({
    campaignLeads: [],
    campaign: {},
  });
  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        `${process.env.REACT_APP_PRONTOPLAY_API}/campaigns/${campaignId}/leads`,
        { headers: { Authorization: localStorage.getItem("token") } }
      );
      setCampaignLeadsData(result.data);
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [campaignId]);

  const formatDateBR = (date) =>
    !!date
      ? moment.tz(date, "America/Sao_Paulo").format("DD/MM/YYYY HH:mm")
      : "";

  const formatReplyDate = (campaignLead) =>
    campaignLead.lead.last_received_msg_ts > campaignLead.sent_at
      ? formatDateBR(campaignLead.lead.last_received_msg_ts)
      : "";

  const formatStatus = (campaignLead) => {
    if (campaignLead.status === "created") {
      return "Aguardando envio";
    } else if (campaignLead.status === "invalid") {
      return "Número inválido";
    } else if (!!campaignLead.sent_at) {
      if (campaignLead.lead.last_received_msg_ts > campaignLead.sent_at) {
        return "Respondido";
      } else if (campaignLead.status === "sent") {
        return "Enviado";
      } else if (campaignLead.status === "DELIVERY_ACK") {
        return "Recebido";
      } else if (campaignLead.status === "READ") {
        return "Lido";
      }
    }
  };

  const csvHeaders = !!campaignLeadsData.campaign.columns
    ? [
        ...campaignLeadsData.campaign?.columns?.map((column) => ({
          label: column,
          key: column,
        })),
        { label: "Status", key: "status" },
        { label: "Data Envio", key: "sent_at" },
        { label: "Data Resposta", key: "replied_at" },
      ]
    : [];

  const csvData = campaignLeadsData.campaignLeads.map((campaignLead) => ({
    ...campaignLead.lead.params,
    status: formatStatus(campaignLead),
    sent_at: formatDateBR(campaignLead.sent_at),
    replied_at: formatReplyDate(campaignLead),
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Title>Envios da campanha</Title>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="text.secondary">
                  {campaignLeadsData.campaign.name}
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename="campanha.csv"
                >
                  <IconButton edge="end" aria-label="delete">
                    <FileDownloadIcon />
                  </IconButton>
                </CSVLink>
              </Grid>
            </Grid>
            <br />
            <Table size="small">
              <TableHead>
                <TableRow>
                  {campaignLeadsData.campaign?.columns?.map((column) => (
                    <TableCell>{column}</TableCell>
                  ))}
                  <TableCell>Status</TableCell>
                  <TableCell>Data Envio</TableCell>
                  <TableCell>Data Resposta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignLeadsData.campaignLeads.map((campaignLead) => (
                  <TableRow key={campaignLead.id}>
                    {campaignLeadsData.campaign?.columns.map((column) => (
                      <TableCell>{campaignLead.lead.params[column]}</TableCell>
                    ))}
                    <TableCell>{formatStatus(campaignLead)}</TableCell>
                    <TableCell>{formatDateBR(campaignLead.sent_at)}</TableCell>
                    <TableCell>{formatReplyDate(campaignLead)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Link
              color="primary"
              href="#"
              onClick={preventDefault}
              sx={{ mt: 3 }}
            >
              Ver mais
            </Link> */}
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
