import * as React from "react";
import { useNavigate } from "react-router-dom";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LeadFields from "./LeadFields";
import LeadInsights from "./LeadInsights";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { TitleOutlined } from "@mui/icons-material";

export default function LeadsKanbanColumn(props) {
  const navigate = useNavigate();
  const { leads, label, tenant } = props;

  const handleDetailsClick = (route) => (e) => {
    e.preventDefault();
    navigate(route);
    return;
  };

  return (
    <Grid item xs={4} spacing={2} rowSpacing={2}>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          width: "100%",
          style: { backgroundColor: "blue" },
        }}
      >
        <CardContent sx={{ flex: 1, height: 20 }}>
          <Title>{label}</Title>
        </CardContent>
      </Card>
      {leads.map((lead) => (
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
            marginTop: 1,
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Link
              color="primary"
              sx={{ mt: 3 }}
              onClick={handleDetailsClick(`/leads/${lead.id}`)}
            >
              <Typography component="h2" variant="h5">
                {lead.params[tenant.view.kanban.cardTitleParam]}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {tenant.view.kanban.cardSubtitleParams.map((subtitleParam) => (
                  <div>
                    {lead.params[subtitleParam]}
                    <br />
                  </div>
                ))}
              </Typography>
            </Link>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
}
