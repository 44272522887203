import * as React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";

export default function Leads() {
  const navigate = useNavigate();
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const handleDetailsClick = (route) => (e) => {
    e.preventDefault();
    navigate(route);
    return;
  };

  const [leadViewData, setLeadViewData] = useState({
    tenant: {},
    leads: [],
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios(
          `${process.env.REACT_APP_PRONTOPLAY_API}/leadView`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );
        result.data.leads.sort((a, b) => {
          let left = parseInt(
            a.params[result.data.tenant.view.leads.orderBy.column] ?? 0
          );
          let right = parseInt(
            b.params[result.data.tenant.view.leads.orderBy.column] ?? 0
          );
          if (result.data.tenant.view.leads.orderBy.direction === "asc") {
            return left - right;
          } else {
            return right - left;
          }
        });
        setLeadViewData(result.data);
      } catch (err) {
        console.log(`Error when trying to fetch data`);
      }
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const csvHeaders = !!leadViewData.tenant.view?.leads?.csv
    ? [
        { label: "Whatsapp", key: "whatsapp" },
        ...leadViewData.tenant.view?.leads?.csv?.map((column) => ({
          label: column.label,
          key: column.id,
        })),
      ]
    : [];

  const csvData = leadViewData.leads.map((lead) =>
    leadViewData.tenant.view?.leads?.csv?.reduce(
      (row, col) => {
        row[col.id] = lead.params[col.id];
        return row;
      },
      { whatsapp: lead.whatsapp }
    )
  );

  console.log(JSON.stringify(csvData));
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Title>Leads</Title>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="text.secondary">
                  {leadViewData.tenant.name}
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename="leads.csv"
                >
                  <IconButton edge="end" aria-label="delete">
                    <FileDownloadIcon />
                  </IconButton>
                </CSVLink>
              </Grid>
            </Grid>
            <br />
            <Table size="small">
              <TableHead>
                <TableRow>
                  {leadViewData.tenant.view?.leads?.columns.map((column) => (
                    <TableCell>{column.label}</TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadViewData.leads.map((lead) => (
                  <TableRow key={lead.id}>
                    {leadViewData.tenant.view?.leads?.columns.map((column) => (
                      <TableCell>{lead.params[column.id]}</TableCell>
                    ))}
                    <TableCell>
                      <Link
                        color="primary"
                        sx={{ mt: 3 }}
                        href="#"
                        onClick={handleDetailsClick(`/leads/${lead.id}`)}
                      >
                        Detalhes
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Link
              color="primary"
              href="#"
              onClick={preventDefault}
              sx={{ mt: 3 }}
            >
              Ver mais
            </Link> */}
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
