import * as React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import moment from "moment-timezone";

export default function Campaigns() {
  const navigate = useNavigate();
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const handleButtonRoute = (route) => (e) => {
    e.preventDefault();
    navigate(route);
    return;
  };

  const handleChangeCampaignStatus =
    (campaignId, campaignStatus) => (event) => {
      event.preventDefault();
      const data = { status: campaignStatus };

      async function doPost() {
        try {
          const result = await axios({
            method: "post",
            url: `${process.env.REACT_APP_PRONTOPLAY_API}/campaigns/${campaignId}`,
            headers: {
              Authorization: localStorage.getItem("token"),
            },
            data,
          });

          alert("Status da campanha alterado com sucesso.");
        } catch (err) {
          const errMsgs = !!err?.response?.data?.errors
            ? err.response.data.errors.map((item) => item.message)
            : [];
          alert(
            `Erro ao tentar alterar o status da campanha: ${errMsgs.join("\n")}`
          );
        }
      }

      doPost();
    };

  const [campaignsData, setCampaignsData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios(
          `${process.env.REACT_APP_PRONTOPLAY_API}/campaigns`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );
        setCampaignsData(result.data);
      } catch (err) {}
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const formatDateBR = (date) =>
    moment.tz(date, "America/Sao_Paulo").format("DD/MM/YYYY HH:mm");

  const campaignStatusMap = {
    created: {
      label: "Aguardando início",
      possibleNewStatus: { id: "running", label: "Iniciar" },
    },
    running: {
      label: "Rodando",
      possibleNewStatus: { id: "paused", label: "Pausar" },
    },
    paused: {
      label: "Pausada",
      possibleNewStatus: { id: "running", label: "Continuar" },
    },
    finished: { label: "Concluída", possibleNewStatus: undefined },
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Title>Campanhas</Title>
            <br />
            <Grid container>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={handleButtonRoute(`/campanhas/nova`)}
                >
                  Criar nova campanha
                </Button>
              </Grid>
            </Grid>

            <br />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Início</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignsData.map((campaign) => (
                  <TableRow key={campaign.id}>
                    <TableCell>{campaign.name}</TableCell>
                    <TableCell>{formatDateBR(campaign.start_at)}</TableCell>
                    <TableCell>
                      {campaignStatusMap[campaign.status].label}
                    </TableCell>
                    <TableCell>
                      {!!campaignStatusMap[campaign.status].possibleNewStatus && (
                        <Link
                          color="primary"
                          sx={{ mt: 3 }}
                          href="#"
                          onClick={handleChangeCampaignStatus(
                            campaign.id,
                            campaignStatusMap[campaign.status].possibleNewStatus
                              .id
                          )}
                        >
                          {
                            campaignStatusMap[campaign.status].possibleNewStatus
                              .label
                          }
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      <Link
                        color="primary"
                        sx={{ mt: 3 }}
                        href="#"
                        onClick={handleButtonRoute(`/campanhas/${campaign.id}`)}
                      >
                        Editar
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="primary"
                        sx={{ mt: 3 }}
                        href="#"
                        onClick={handleButtonRoute(`/campanhas/${campaign.id}/leads`)}
                      >
                        Envios
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Link
              color="primary"
              href="#"
              onClick={preventDefault}
              sx={{ mt: 3 }}
            >
              Ver mais
            </Link> */}
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
