import * as React from "react";
import { useNavigate } from "react-router-dom";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LeadFields from "./LeadFields";
import LeadInsights from "./LeadInsights";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { TitleOutlined } from "@mui/icons-material";
import LeadsKanbanColumn from "./LeadsKanbanColumn";

export default function LeadsKanban() {
  const navigate = useNavigate();
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const [leadViewData, setLeadViewData] = useState({
    leads: [],
    tenant: null,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios(
          `${process.env.REACT_APP_PRONTOPLAY_API}/leadView`,
          { headers: { Authorization: localStorage.getItem("token") } }
        );
        setLeadViewData(result.data);
      }
      catch (err) {
        console.log(`Error when trying to fetch data`)
      }
      
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <Grid container spacing={3}>
      {leadViewData.tenant?.view?.kanban?.columns?.map((column) => (
        <LeadsKanbanColumn
          leads={leadViewData.leads.filter(
            (lead) =>
              lead.params[leadViewData.tenant?.view?.kanban?.columnsParam] ===
              column.value
          )}
          label={column.label}
          tenant={leadViewData.tenant}
        />
      ))}
    </Grid>
  );
}
