import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";

// Generate Order Data
function createData(id, nome, cargo, empresa, ultimaMensagem) {
  return { id, nome, cargo, empresa, ultimaMensagem };
}

function preventDefault(event) {
  event.preventDefault();
}

export default function LeadsQuentes() {
  const rows = [
    createData(1, "Francisco Krieser", "CEO", "ZapCampaign", "04/09/2024"),
    createData(2, "Maria Oliveira", "CMO", "DigitalSoft", "03/09/2024"),
    createData(3, "André dos Santos", "Gerente Marketing", "EcoSoft", "03/09/2024"),
    createData(4, "Paulo Santos", "CEO", "Softhouse", "03/09/2024"),
    createData(5, "Ana Paula Ortiz", "Founder", "Horti",  "03/09/2024"),
  ];
  return (
    <React.Fragment>
      <Title>Leads Quentes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Cargo</TableCell>
            <TableCell>Empresa</TableCell>
            <TableCell>Última Mensagem</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.nome}</TableCell>
              <TableCell>{row.cargo}</TableCell>
              <TableCell>{row.empresa}</TableCell>
              <TableCell>{row.ultimaMensagem}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        Ver mais
      </Link>
    </React.Fragment>
  );
}
