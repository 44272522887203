import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const res = await axios(`${process.env.REACT_APP_PRONTOPLAY_API}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
      // const res = await axios(`${process.env.REACT_APP_PRONTOPLAY_API}/googleLogin`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data,
      // });
    
      if (res.data) {
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        navigate("/dashboard");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      alert("Credenciais inválidas. Por favor, entre com email e senha válidos.");
      console.error(err);
    }
  };

  const logOut = () => {
    setToken("");
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
