import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

function LeadInsights(props) {
  const { lead } = props;

  return (
    <Grid item xs={12} md={8} lg={8} style={{ display: "flex" }}>
      {/* <CardActionArea component="a" href="#"> */}
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ flex: 1 }}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {!!lead.tenant ? lead.tenant.view.leadDetails.summary.label : ""}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {!!lead.tenant
              ? lead.params[lead.tenant.view.leadDetails.summary.id]
              : ""}
          </Typography>
        </CardContent>
      </Card>
      {/* </CardActionArea> */}
    </Grid>
  );
}

export default LeadInsights;
