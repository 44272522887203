import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

function LeadFields(props) {
  const { lead } = props;

  return (
    <Grid item xs={12} md={4} lg={4} style={{ display: "flex" }}>
      {/* <CardActionArea component="a" href="#"> */}
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <CardContent sx={{ flex: 1 }}>
          <Typography component="h2" variant="h5">
            {!!lead.tenant
              ? lead.params[lead.tenant?.view?.leadDetails?.cardTitle?.id]
              : ""}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Whatsapp: {lead.whatsapp}
          </Typography>

          <Typography paragraph>
            <br/>
            {lead.tenant?.view?.leadDetails?.cardFields?.map((field) => (
              <div>
                <b>{field.label}</b>: {lead.params[field.id]}
                <br />
              </div>
            ))}
          </Typography>
        </CardContent>
      </Card>
      {/* </CardActionArea> */}
    </Grid>
  );
}

export default LeadFields;
