import * as React from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CampaignIcon from "@mui/icons-material/Campaign";

export function MainListItems() {
  const navigate = useNavigate();

  const handleMenuItemClick = (route) => (e) => {
    e.preventDefault();
    navigate(route);
    return;
  };

  return (
    <React.Fragment>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          onClick={handleMenuItemClick("/dashboard")}
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          primary="Kanban"
          onClick={handleMenuItemClick("/kanban")}
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Leads" onClick={handleMenuItemClick("/leads")} />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>
        <ListItemText
          primary="Campanhas"
          onClick={handleMenuItemClick("/campanhas")}
        />
      </ListItemButton>
      {/* <ListItemButton>
        <ListItemIcon>
          <CalendarMonthIcon />
        </ListItemIcon>
        <ListItemText primary="Agendamentos" />
      </ListItemButton> */}

      <ListItemButton>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText
          primary="Configurações"
          onClick={handleMenuItemClick("/configuracoes")}
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ContactSupportIcon />
        </ListItemIcon>
        <ListItemText primary="Suporte" />
      </ListItemButton>
    </React.Fragment>
  );
}
